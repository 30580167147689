import React, { useMemo } from "react";
import "./Breadcrumbs.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import BackSvg from "../../assets/images/BackSvg";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_VENUE } from "../../graphql/query/getVenue";
import breadcrumbStore from "../../store/breadcrumbStore";
import { GET_EVENT_INFO } from "../../graphql/query/getEventInfo";
import {
  INavItem,
  prepareBlogItems,
  prepareEventItems,
  prepareInitialNavItems,
  prepareTicketItems,
  prepareVenueItems,
} from "./helper";
import ticketsStore from "../../store/ticketsStore";
import { stripSpecialChars } from "../../utils/common";
import { GET_BLOG } from "../../graphql/query/getBlog";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";

const Breadcrumbs = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { venueId, eventId, ticketId, blogId } = useParams();
  const [venueNavItems, setVenueNavItems] = useState<INavItem[]>([]);
  const [eventNavItems, setEventNavItems] = useState<INavItem[]>([]);
  const [ticketNavItems, setTicketNavItems] = useState<INavItem[]>([]);
  const [blogNavItems, setBlogNavItems] = useState<INavItem[]>([]);

  const [getEvent, { data: eventData, error: eventError }] = useLazyQuery(GET_EVENT_INFO, {
    fetchPolicy: "no-cache",
  });

  const [getVenue, { data: venueData, error: venueError }] = useLazyQuery(GET_VENUE, {
    fetchPolicy: "no-cache",
  });

  const [getBlog, { data: blogData, error: blogError }] = useLazyQuery(GET_BLOG, {
    fetchPolicy: "no-cache",
  });

  const firstQueryParam = location.pathname.split("/")[1];

  useEffect(() => {
    (async () => {
      if (eventId) {
        if (!breadcrumbStore.event || breadcrumbStore.event.id !== eventId) {
          await getEvent({
            variables: {
              id: +eventId!,
            },
          }).then((res) => {
            if (res?.data?.findEventInfo) {
              breadcrumbStore.addEvent(res.data.findEventInfo);
            }
            return res;
          });
        }
      } else if (venueId) {
        if (!breadcrumbStore.venue || breadcrumbStore.venue.id !== venueId) {
          await getVenue({
            variables: {
              id: +venueId,
            },
          }).then((res) => {
            if (res?.data?.getVenue) {
              breadcrumbStore.addVenue(res.data.getVenue);
            }
            return res;
          });
        }
      } else if (blogId) {
        if (!breadcrumbStore.blog || breadcrumbStore.blog.id !== blogId) {
          await getBlog({
            variables: {
              id: +blogId,
            },
          }).then((res) => {
            if (res?.data?.getBlog) {
              breadcrumbStore.addBlog(res.data.getBlog);
            }
            return res;
          });
        }
      }
    })();
  }, [venueId, eventId, blogId, firstQueryParam]);

  useEffect(() => {
    if (breadcrumbStore.eventInfo) {
      setEventNavItems(prepareEventItems(breadcrumbStore.eventInfo));
    }
  }, [breadcrumbStore.eventInfo]);

  useEffect(() => {
    if (breadcrumbStore.venueInfo) {
      setVenueNavItems(prepareVenueItems(breadcrumbStore.venueInfo));
    }
  }, [breadcrumbStore.venueInfo]);

  useEffect(() => {
    if (breadcrumbStore.blogInfo) {
      setBlogNavItems(prepareBlogItems(breadcrumbStore.blogInfo));
    }
  }, [breadcrumbStore.blogInfo]);

  useEffect(() => {
    if (ticketsStore.ticket) {
      setTicketNavItems(prepareTicketItems(ticketsStore.ticket, eventId, venueId));
    }
  }, [ticketId, ticketsStore.ticket]);

  const navItems = useMemo(() => {
    let items: INavItem[] = [];
    if (firstQueryParam !== "" && firstQueryParam !== "venues") {
      items = prepareInitialNavItems(firstQueryParam);
    } else {
      if (eventId) {
        items = eventNavItems;
      } else if (venueId) {
        items = venueNavItems;
      } else if (blogId) {
        items = blogNavItems;
      } else {
        items = prepareInitialNavItems("venues");
      }

      if (ticketId) {
        items = navItems.concat(ticketNavItems);
      }
    }
    return items;
  }, [
    blogId,
    blogNavItems,
    eventId,
    eventNavItems,
    firstQueryParam,
    ticketId,
    ticketNavItems,
    venueId,
    venueNavItems,
  ]);

  const breadCrumbItems = useMemo(
    () =>
      navItems.length
        ? navItems.map((navItem, index) => {
            const item: Partial<BreadcrumbItemType> = {
              title: stripSpecialChars(navItem.label),
            };
            if (index !== navItems.length - 1) {
              item.href = navItem.path;
            }
            return item;
          })
        : [],
    [navItems]
  );
  return (
    <div className={"breadcrumb--header"}>
      <BackSvg className={"breadcrumb--header--back"} onClick={() => navigate(-1)} />
      <Breadcrumb items={[{ title: "Home", href: "/" }, ...breadCrumbItems]}></Breadcrumb>
    </div>
  );
});

export default Breadcrumbs;
